import './css/App.css';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <p className="landing-page-title">Shoreline Exterminators</p>
      <Routes>
        <Route path="/" element={<></>} />
      </Routes>
    </div>
  );
}

export default App;
